

export const environment = {
    production: false,

    //Bhive Backend and FrontEnd

    server: 'EU',
    homeUrl: 'https://login1.test.thebhive.net',
    baseHref: 'https://gw1.test.thebhive.net',
    otherHomeUrl: 'https://login.test.thebhive.net',
    otherServer: 'https://gw.test.thebhive.net',

    //ZDSC Gateway Process
    // gatewayAuthenticationKey: "B3D9EABCBCA77424E0530100007F8422",
    gatewayAuthenticationKey: "B3EB14D9068472DCE0530100007FFB5A",

    gatewayUrl: 'https://www.my-aip.com/Gateway/rest',
    cubeAPI: 'https://analytics.test.thebhive.net/cubejs-api/v1',
    incheckServiceUrl:
        'https://gs.test.thebhive.net/gateway-services/api/gateway',
    incheckServiceMockUrl: 'https://gs.test.thebhive.net/gateway-services/api/gateway',
    sdsAPI: 'https://po.test.thebhive.net/po-services',
    faqUrl: 'http://www.thebhive.net/membersfaq',
    metaBaseURL: 'https://get.thebhive.net',
    serverLocation: 'Europe',
    recaptchaKey: '6LeDV7saAAAAAKvmnxnXmbI2jK1C1MnlUMr7_yNj',
    poServiceUrl: 'https://po.test.thebhive.net/po-services/api',
    authentication: {
        enabled: true,

        url: 'http://bluhive.slashcube.de:8080/api/login',
    },
    keycloakURL:'https://auth.thebhive.cn/auth'
};


// export const environment = {
//     production: false,

//     //Bhive Backend and FrontEnd
//     server: 'EU',
//     homeUrl: 'https://login.test.thebhive.net',
//     baseHref: 'https://gw.test.thebhive.net',
//     otherHomeUrl: 'https://login1.test.thebhive.net',
//     otherServer: 'https://gw1.test.thebhive.net', // this should be china or europe to transfer request between servers.

//     // server: 'CN',
//     // homeUrl: 'https://login1.test.thebhive.net',
//     // baseHref: 'https://gw1.test.thebhive.net',
//     // otherHomeUrl: 'https://login.test.thebhive.net',
//     // otherServer: 'https://gw.test.thebhive.net',

//     //ZDSC Gateway Process
//     gatewayUrl: 'https://beta.my-aip.com/Gateway/rest',
//     // gatewayAuthenticationKey: "B3D9EABCBCA77424E0530100007F8422",
//     // gatewayUrl: 'https://www.my-aip.com/Gateway/rest',
//     gatewayAuthenticationKey: 'B3EB14D9068472DCE0530100007FFB5A',
//     cubeAPI: 'https://analytics.test.thebhive.net/cubejs-api/v1',
//     incheckServiceUrl:
//         'https://gs.test.thebhive.net/gateway-services/api/gateway',
//     sdsAPI: 'https://po.test.thebhive.net/po-services',
//     faqUrl: 'http://www.thebhive.net/membersfaq',
//     metaBaseURL: 'https://get.thebhive.net',
//     serverLocation: 'Europe',
//     recaptchaKey: '6LeDV7saAAAAAKvmnxnXmbI2jK1C1MnlUMr7_yNj',
//     poServiceUrl: 'https://po.test.thebhive.net/po-services/api',
//     authentication: {
//         enabled: true,

//         url: 'http://bluhive.slashcube.de:8080/api/login',
//     },
//    keycloakURL:'https://auth.thebhive.net/auth'
// };
