import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OrganizationType } from 'app/globals-classes/OrganizationType';
import { GlobalsService } from 'app/globals-services/globals.service';
import { SessionStateService } from 'app/globals-services/session-state-service/session-state.service';
import { CubejsService } from 'app/shared/cubejs/cubejs.service';
import { MessageDialogComponent } from 'app/shared/message-dialog/message-dialog.component';
import moment from 'moment';

// declare var jsPDF: any;


@Component({
  selector: 'app-formula-pie-chart-template',
  templateUrl: './formula-pie-chart-template.component.html',
  styleUrls: ['./formula-pie-chart-template.component.scss'],
})
export class FormulaPieChartTemplateComponent implements OnInit {
  @Input() hideDate: boolean;
  @Input() hideFactory: boolean;
  @Input() isSingleInventory: boolean;
  @Input() startDateString: string;
  @Input() endDateString: string;
  @Input() inventoryID: number;
  @Input() factoryID = {};
  connectedBrandsScopeCriteria: any;
  defaultZDHCMemberScopeValue: any;
  defaultZDHCComplianceValue: any;
  defaultNonZDHCMemberScopeValue: any;
  defaultNonZDHCComplianceValue: any;
  hasScopeCommodities: boolean = false;
  organizationScope: string = '';

  isLoading: boolean;
  errorList = [];
  isCubejsConnected: boolean = true;
  iszdhcGateway: boolean = false;
  isBrandZdhc: Boolean = GlobalsService.isBrandZdhcMember;
  organizationId: string; //must be a string
  latestFormulas = []
  zdhcGatewaySubscriptionStatus: Boolean;
  selectedCountry: string = "";
  selectedSupplier: string = "";
  selectedSupplierName: string = "";
  suggestedList: any = [];
  standardsList = [];
  suppliersToShow = [];
  startDate;
  endDate;
  chartData: Object[] = [];
  commodities_value: String[] = [];
  sdate: string;
  edate: string;
  distinctFormulaCount = 0;
  selectedStandards = new FormControl();
  commoditiesControl = new FormControl(false);
  brandScopeControl = new FormControl(true);
  inventory_report: string = 'Inventory Report';
  @ViewChild('mySelect') mySelect: any;
  hasComplianceCommodities: boolean;
  organizationCompliance: any;
  potentialPartner = [];
  connectedPartnerList = [];
  includeCommodities: boolean = false;
  hasBulkScope: boolean = false;

  constructor(private cubejsService: CubejsService,
    private globalsService: GlobalsService,
    private http: HttpClient,
    private sessionStateService: SessionStateService,
    private route: ActivatedRoute, public dialog: MatDialog) { }

  ngOnInit(): void {

    this.startDate = this.startDateString ? new FormControl(new Date(this.startDateString)) : new FormControl(moment().subtract(5, 'months').startOf('month').format('YYYY-MM-DD'));
    this.endDate = this.endDateString ? new FormControl(new Date(this.endDateString)) : new FormControl(moment().endOf('month').format('YYYY-MM-DD'));
    
    this.getZDHCSubscriptionStatus();
    this.getConnectedListPartner();
    this.organizationId = this.globalsService.profile["id"].toString();
    this.http.get<any>(this.globalsService.baseHref + '/complianceCriteria/getBrandsWithScopeAndCompliance').subscribe((response) => {
      // if (response.length != 0) {
        this.connectedBrandsScopeCriteria = response['brands'];
        this.defaultZDHCMemberScopeValue = response['defaultZDHCMemberScopeValue'];
        this.defaultZDHCComplianceValue = response['defaultZDHCComplianceValue'];
        this.defaultNonZDHCMemberScopeValue = response['defaultNonZDHCMemberScopeValue'];
        this.defaultNonZDHCComplianceValue = response['defaultNonZDHCComplianceValue'];
        // let listOfRules = response[0].listOfRules;
      //   if (listOfRules != 0) {
      //     listOfRules.forEach(element => {
      //       if (element.members.some(i => i.memberValue.includes('basic chemical'))) {
      //         this.commoditiesControl.setValue(true);
      //       }
      //     });
      //   }
      //   else this.commoditiesControl.setValue(false);
      // }
      // else this.commoditiesControl.setValue(false);
      setTimeout(() => {
        this.http.get<any>(this.globalsService.baseHref + '/complianceCriteria/standards').subscribe(
          (response) => {
            let defaultChart;
            // response['standards'] = response['standards'].filter(function (standard) {
            //   return !standard.hasOwnProperty('id') || standard['name'] === "Inditex";
            // });
            this.standardsList = response['standards']; //get all available standards
            console.log(this.standardsList);
            if (this.isBrand || this.isImporter) {
              if (GlobalsService.isVF) {
                let chemIq = {
                  "name": "chemIq",
                  "displayName": "ChemIQ"
                };
                this.standardsList.push(chemIq);
              }
              this.organizationScope = this.connectedBrandsScopeCriteria.find(res => res.id == this.globalsService.profile['id'])['scopeValue'];
              this.organizationCompliance = this.connectedBrandsScopeCriteria.find(res => res.id == this.globalsService.profile['id'])['complianceValue'];
              // this.hasScopeCommodities = this.organizationScope ? this.organizationScope.includes('Bulk / Commodities'): false;
              this.hasComplianceCommodities = this.organizationCompliance ? this.organizationCompliance.includes('Basic Chemicals'): false;
              if (this.isBrandZdhc) {
                defaultChart = {
                  "name": "mixedZdhc",
                  "displayName": "ZDHC MRSL(Crowd-sourced) + ZDHC MRSL(Gateway)"
                };
                this.standardsList.push(defaultChart);
              }
              else {
                this.standardsList = this.standardsList.filter(function (obj) {
                  return obj['name'] !== "zdhcGateway";
                });
                defaultChart = this.standardsList.find(el => el.name === "zdhcMrsl");
              }

            }
            else {
              if (this.zdhcGatewaySubscriptionStatus) {
                this.organizationScope = this.defaultZDHCMemberScopeValue;
                this.organizationCompliance = this.defaultNonZDHCComplianceValue;
                // this.hasScopeCommodities = this.organizationScope.includes('Bulk / Commodities');
                this.hasComplianceCommodities = this.organizationCompliance.includes('Basic Chemicals');
                defaultChart = {
                  "name": "mixedZdhc",
                  "displayName": "ZDHC MRSL(Crowd-sourced) + ZDHC MRSL(Gateway)"
                };
                this.standardsList.push(defaultChart);
              }
              else {
                this.organizationScope = this.defaultNonZDHCMemberScopeValue;
                this.organizationCompliance = this.defaultZDHCComplianceValue;
                // this.hasScopeCommodities = this.organizationScope.includes('Bulk / Commodities');
                this.hasComplianceCommodities = this.organizationCompliance.includes('Basic Chemicals');
                this.standardsList = this.standardsList.filter(function (obj) {
                  return obj['name'] !== "zdhcGateway";
                });
                defaultChart = this.standardsList.find(el => el.name === "zdhcMrsl");
              }
            }


            if (this.isBrand || this.isImporter) {
              this.route.queryParams.subscribe(
                (state) => {
                  if (state['creatorID'] != null) {
                    this.selectedSupplier = state['creatorID']
                    let sp = this.globalsService.brands.find(supplier => supplier.id == this.selectedSupplier);
                    this.selectedSupplierName = sp.name
                    this.ifSupplierAlreadySelected()
                  }

                  if (state['accState'] == 'open') {
                    this.selectedCountry = this.sessionStateService.getChemInvCountry();
                    this.selectedSupplier = this.sessionStateService.getChemInvSupplier();
                    this.fetchAll();
                  }
                  const standardWithId = this.standardsList.find(standard => standard.hasOwnProperty('id'));
                  const defaultCharts = [defaultChart, standardWithId].filter(chart => chart !== null);
                  this.selectedStandards.setValue(defaultCharts);
                }
              );
              this.getMySupplierPartners();
            }
            else {
              this.selectedStandards.setValue([defaultChart]);
            }
          },
          (err) => {
            console.log(err);
            //this.loading = false;
          }
        );
      }, 500);
    }
    )
  }



  get isBrand(): boolean { //is user role or organization type
    return this.globalsService.profile["organization_type"] == OrganizationType.BRAND
  }

  get isImporter(): boolean {
    return this.globalsService.profile["organization_type"] == OrganizationType.IMPORTER
  }

  getConnectedListPartner() {
    this.potentialPartner = [];
    this.connectedPartnerList = []

    for (const brand of this.globalsService.brands) {
      if (brand['sharedWith']) {
        let ownerID = '';
        let brandID = '';

        if (!(this.isBrand || this.isImporter)) {
          // if factory
          ownerID = this.globalsService.profile['id'];
          brandID = brand['id'];

          this.http
            .post<any>(
              this.globalsService.baseHref + '/user/getSupplierID',
              { brand: brandID, owner: ownerID }
            )
            .subscribe((object) => {
              if (object['idValue'] !== undefined) {
                brand.idName = object['idName'];
                brand.idValue = object['idValue'];
              } else {
                brand.idValue = '';
              }
            });

        } else {
          //if brand logged in
          ownerID = brand['id']; //logged in Brand
          brandID = this.globalsService.profile['id'];
        }

        if (!(brand.id == this.globalsService.profile['id'])) {
          this.connectedPartnerList.push(brand);
        }

      } else {
        if (brand.name != 'ASOS')
          this.potentialPartner.push(brand);
      }
    }
  }

  ShowErrorMessage(msg) {
    this.isLoading = false;
    this.isLoading = false;
    let customMSg = false;
    if (msg.includes("No id found in Security Context")) {
      msg = 'No factories are connected to this account, so no data can be displayed.\n Ask your factories to connect to you to see their data.',
        customMSg = true;
    }
    if (msg.includes("Network request failed")) {
      this.isCubejsConnected = false;
      throw new Error("CubeJS is not reachable!")
      // return false;
    }
    if (!this.errorList.includes(msg)) {
      this.errorList.push(msg);
      this.dialog.open(MessageDialogComponent, {
        hasBackdrop: true,
        width: "32rem",
        disableClose: true,
        data: {
          message: msg,
          hasLink: customMSg ? true : false,
          goToLink: customMSg ? "How to share inventories" : "",
          URL: customMSg ? "https://cloud.goblu.net/s/4LXyFHrE86Tzx2A" : ""
        },
      });
      // alert(msg);
    }
  }

  fetchAll() {
    this.isLoading = true;
    // On success fetch suppliers
    this.http.get<string[]>(this.globalsService.baseHref + "/inventories/getSuppliers/" + this.selectedCountry).subscribe(
      (response) => {
        this.suppliersToShow = response;
        this.selectedSupplierName = this.suppliersToShow.find(supplier => {
          return (supplier.id === Number(this.selectedSupplier));
        })["name"];
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    )
  }

  rememberSelectedCountry() {
    this.sessionStateService.setChemInvCountry(this.selectedCountry);
  }

  rememberSelectedSupplier() {
    this.sessionStateService.setChemInvSupplier(this.selectedSupplier);
  }
  ifSupplierAlreadySelected() {
    this.rememberSelectedSupplier();
    this.rememberSelectedCountry();
  }
  getZDHCSubscriptionStatus() {

    const data = { userId: this.globalsService.profile['id'] };
    // const data = {userId: this.globalsService.profile['organization_id']};

    this.http.post<any>(this.globalsService.bhiveIncheckServiceUrl + "/subscription/getStatus", data).subscribe((status) => {
      this.zdhcGatewaySubscriptionStatus = (status['status'] === 2);
    }, (err) => {
      if (err['status'] == '404') {
        //if the user the not exist, then the gateway subscription is set to false.
        this.zdhcGatewaySubscriptionStatus = false;
      }
      console.log(err);
    }
    );
  }
  getMySupplierPartners() {
    this.suggestedList = this.globalsService.brands.filter(res => {
      return res.sharedWith == true && res.organizationType == 2 && res.relation === "Connected" && res.id != this.globalsService.profile['id'];
    })
  }
  changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      obj[newKey] = obj[originalKey];
      delete (obj[originalKey]);
      newArr.push(obj);
    }
    return newArr;
  }

  getFormulasFirst(standard) {
    this.isLoading = true;
    let std = new Date(this.startDate.value);
    let edd = new Date(this.endDate.value);
    this.commodities_value = this.commoditiesControl.value == true ? ['0', '1'] : ['0'];
    if (standard.hasOwnProperty('id'))
      this.hasBulkScope = this.connectedBrandsScopeCriteria.find(res => res.id == standard['id']).complianceValue ? this.connectedBrandsScopeCriteria.find(res => res.id == standard['id']).scopeValue.includes('Bulk / Commodities') : false;
    this.sdate = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
    this.edate = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
    let supplier = (this.isBrand || this.isImporter) && (this.selectedSupplier && Number(this.selectedSupplier) != 0) ? Number(this.selectedSupplier) : null;
    let measures = (this.isBrand || this.isImporter) && supplier !== null ? [
      "Formulas.organization_id",
      "Formulas.chemical_identifier"
    ] : [
      "Formulas.chemical_identifier"
    ];
    let measures_inventory = [
      "Formulas.organization_id",
      "Formulas.chemical_identifier"
    ]
    let inventory_filters = this.isSingleInventory ? [
      {
        "member": "Formulas.scan_date",
        "operator": "inDateRange",
        "values": [
          this.startDateString,
          this.endDateString
        ]
      },
      {
        "member": "Formulas.inventory_id",
        "operator": "equals",
        "values": [
          this.inventoryID.toString()
        ]
      },
      {
        "member": "Formulas.basic_chemical",
        "operator": "equals",
        "values": this.hasBulkScope ? ['0', '1'] : this.commodities_value
      }
    ] : [
      {
        "member": "Formulas.scan_date",
        "operator": "inDateRange",
        "values": [
          this.startDateString,
          this.endDateString
        ]
      },
      {
        "member": "Formulas.basic_chemical",
        "operator": "equals",
        "values": this.hasBulkScope ? ['0', '1'] : this.commodities_value
      }
    ];

    let inventory_report_filters = [
      {
        "member": "Formulas.scan_date",
        "operator": "inDateRange",
        "values": [
          this.sdate,
          this.edate
        ]
      },

      {
        "member": "Formulas.basic_chemical",
        "operator": "equals",
        "values": this.hasBulkScope ? ['0', '1'] : this.commodities_value
      }
    ];

    let query = {
      "dimensions": this.isSingleInventory == undefined ? measures : measures_inventory,
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "order": [
        [
          "Formulas.chemical_identifier",
          "asc"
        ]
      ],
      "filters": this.isSingleInventory == undefined ? inventory_report_filters : inventory_filters,
      "measures": [
        "Formulas.latest_formula_in_range",
        // "Formulas.formula_distinct_count"
      ]
    };

    this.cubejsService.getCubeJSData(query, supplier, standard['id'] != undefined  ? standard['id'] : null, this.brandScopeControl.value, this.commoditiesControl.value ? true: false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.latest_formula_in_range", "latest_formula", tempData);
        // tempData = this.changeKey("Formulas.formula_distinct_count", "formula_count", tempData);
        this.latestFormulas = tempData.map(function (el) { return el.latest_formula; });
        this.getCubeData(standard);
      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })
  }
  getCubeData(standard: Object) {
    let std = new Date(this.startDate.value);
    let edd = new Date(this.endDate.value);
    this.sdate = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
    this.edate = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    let query = {};
    let supplier;
    let factoryID;
    if (this.isSingleInventory == undefined) {
      supplier = (this.isBrand || this.isImporter) && (this.selectedSupplier && Number(this.selectedSupplier) != 0) ? Number(this.selectedSupplier) : null;
    }
    else {
      factoryID = (this.isBrand || this.isImporter) && this.factoryID["creatorID"] != 0 ? Number(this.factoryID["creatorID"]) : null;
    }
    let invent_report_filters = [{
      "and": [
        {

          "member": "Formulas.scan_date",
          "operator": "inDateRange",
          "values": [
            this.sdate,
            this.edate
          ]

        },
        {
          "or": formulaFilters.length == 0 ? [] : [{
            "member": 'Formulas.id',
            "operator": 'equals',
            "values": formulaFilters
          }]
        }


      ]
    }];
    let invent_filters = [{
      "and": [
        this.isSingleInventory ? {

          "member": "Formulas.inventory_id",
          "operator": "equals",
          "values": [
            this.inventoryID.toString()
          ]

        } : {

          "member": "Formulas.scan_date",
          "operator": "inDateRange",
          "values": [
            this.startDateString,
            this.endDateString
          ]

        },
        {
          "or": formulaFilters.length == 0 ? [] : [{
            "member": 'Formulas.id',
            "operator": 'equals',
            "values": formulaFilters
          }]
        }


      ]
    }];
    if (standard['name'] === "zdhcGateway") {
      this.iszdhcGateway = true;
      query = {
        "measures": [
          "Formulas.formula_distinct_count",
          "Formulas.incheck_level1_compliant_cnt",
          "Formulas.incheck_level2_compliant_cnt",
          "Formulas.incheck_level3_compliant_cnt",
          "Formulas.incheck_registered_compliant_cnt",
          // "Formulas.incheck_oldzdhc_compliant_cnt"
        ],
        "timeDimensions": [
          {
            "dimension": "Formulas.scan_date"
          }
        ],
        "filters": this.isSingleInventory == undefined ? invent_report_filters : invent_filters
      }
      this.cubejsService.getCubeJSData(query, this.isSingleInventory == undefined ? supplier : factoryID, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
        .then(data => {
          let tempData = data['loadResponse']['results'][0]['data'];
          tempData = this.changeKey("Formulas.incheck_level1_compliant_cnt", "level1CompliantCount", tempData);
          tempData = this.changeKey("Formulas.incheck_level2_compliant_cnt", "level2CompliantCount", tempData);
          tempData = this.changeKey("Formulas.incheck_level3_compliant_cnt", "level3CompliantCount", tempData);
          tempData = this.changeKey("Formulas.incheck_registered_compliant_cnt", "registeredCompliantCount", tempData);
          // tempData = this.changeKey("Formulas.incheck_oldzdhc_compliant_cnt", "oldZdhcCompliantCount", tempData);
          tempData = this.changeKey("Formulas.formula_distinct_count", "totalCount", tempData);
          let dt = {
            'name': standard['name'],
            'complianceName': standard['displayName'],
            'chartType': "yes-no-basic",
            'totalValue': tempData[0].totalCount,
            'complianceValue':this.organizationCompliance,
            // 'chartSize': [1000, 320],
            'chartData': [
              { 'name': 'Level 1', 'value': tempData[0].level1CompliantCount },
              { 'name': 'Level 2', 'value': tempData[0].level2CompliantCount },
              { 'name': 'Level 3', 'value': tempData[0].level3CompliantCount },
              { 'name': 'Registered', 'value': tempData[0].registeredCompliantCount },
              // { 'name': 'ZDHC v1.1', 'value': tempData[0].oldZdhcCompliantCount },
              {
                'name': 'Not evaluated',
                'value': tempData[0].totalCount 
                  - tempData[0].registeredCompliantCount - tempData[0].level3CompliantCount
                  - tempData[0].level2CompliantCount - tempData[0].level1CompliantCount
              }
            ]
          };
          this.chartData.push(dt);
          this.chartData = [].concat(this.chartData)
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.ShowErrorMessage(error.message);
        })

    }
    else if (standard['name'] === "mixedZdhc") {
      query = {
        "measures": [
          "Formulas.formula_distinct_count",
          "Formulas.mixed_zdhc_gateway_compliant_cnt",
          "Formulas.mixed_zdhc_bhive_compliant_cnt",
          "Formulas.mixed_zdhc_basic_cnt",
        ],
        "timeDimensions": [
          {
            "dimension": "Formulas.scan_date"
          }
        ],
        "filters": this.isSingleInventory == undefined ? invent_report_filters : invent_filters
      }
      this.cubejsService.getCubeJSData(query, this.isSingleInventory == undefined ? supplier : factoryID, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
        .then(data => {
          let tempData = data['loadResponse']['results'][0]['data'];
          tempData = this.changeKey("Formulas.formula_distinct_count", "totalCount", tempData);
          tempData = this.changeKey("Formulas.mixed_zdhc_gateway_compliant_cnt", "gatewayCompliantCount", tempData);
          tempData = this.changeKey("Formulas.mixed_zdhc_bhive_compliant_cnt", "bHiveCompliantCount", tempData);
          tempData = this.changeKey("Formulas.mixed_zdhc_basic_cnt", "basicCount", tempData);
          let dt = {};
          if (this.commoditiesControl.value) {
          dt = {
            'name': standard['name'],
            'complianceName': standard['displayName'],
            'chartType': "yes-no-basic",
            'totalValue': tempData[0].totalCount,
            'complianceValue': this.organizationCompliance,
            // 'chartSize': [700, 320],
            'chartData': [
              { 'name': 'ZDHC MRSL(Crowd-sourced) Verified', 'value': tempData[0].bHiveCompliantCount },
              { 'name': 'Commodities', 'value': tempData[0].basicCount },
              { 'name': 'ZDHC MRSL(Gateway) Verified', 'value': tempData[0].gatewayCompliantCount },
              { 'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].gatewayCompliantCount - tempData[0].bHiveCompliantCount - tempData[0].basicCount },
            ]
          };
          }
          else {
            dt = {
              'name': standard['name'],
              'complianceName': standard['displayName'],
              'chartType': "yes-no-basic",
              'totalValue': tempData[0].totalCount,
              'complianceValue': this.organizationCompliance,
              // 'chartSize': [700, 320],
              'chartData': [
                { 'name': 'ZDHC MRSL(Crowd-sourced) Verified', 'value': tempData[0].bHiveCompliantCount },
                { 'name': 'ZDHC MRSL(Gateway) Verified', 'value': tempData[0].gatewayCompliantCount },
                { 'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].gatewayCompliantCount - tempData[0].bHiveCompliantCount - tempData[0].basicCount },
              ]
            };
          }

          // this.distinctFormulaCount = tempData[0].totalCount;
          this.chartData.push(dt);
          this.chartData = [].concat(this.chartData)
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.ShowErrorMessage(error.message);
        })
    }
    else if (standard['name'] === "chemIq") {
      query = {
        "measures": [
          "Formulas.formula_distinct_count",
          "Formulas.chem_iq_allowed_cnt",
          "Formulas.chem_iq_preferred_cnt",
          "Formulas.chem_iq_diligence_cnt",
          "Formulas.chem_iq_prohibited_cnt",
          "Formulas.mixed_zdhc_basic_cnt",
        ],
        "timeDimensions": [
          {
            "dimension": "Formulas.scan_date"
          }
        ],
        "filters": this.isSingleInventory == undefined ? invent_report_filters : invent_filters
      }
      this.cubejsService.getCubeJSData(query, this.isSingleInventory == undefined ? supplier : factoryID, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
        .then(data => {
          let tempData = data['loadResponse']['results'][0]['data'];
          tempData = this.changeKey("Formulas.formula_distinct_count", "totalCount", tempData);
          tempData = this.changeKey("Formulas.chem_iq_allowed_cnt", "allowedCompliantCount", tempData);
          tempData = this.changeKey("Formulas.chem_iq_preferred_cnt", "preferredCompliantCount", tempData);
          tempData = this.changeKey("Formulas.chem_iq_diligence_cnt", "diligenceCompliantCount", tempData);
          tempData = this.changeKey("Formulas.chem_iq_prohibited_cnt", "prohibitedCompliantCount", tempData);
          tempData = this.changeKey("Formulas.mixed_zdhc_basic_cnt", "basicCount", tempData);
          let dt = {};
          if (this.commoditiesControl.value) {
          dt = {
            'name': standard['name'],
            'complianceName': standard['displayName'],
            'chartType': "yes-no-basic",
            'totalValue': tempData[0].totalCount,
            'complianceValue': this.organizationCompliance,
            // 'chartSize': [700, 320],
            'chartData': [
              { 'name': 'Allowed', 'value': tempData[0].allowedCompliantCount },
              { 'name': 'Preferred', 'value': tempData[0].preferredCompliantCount },
              { 'name': 'Req. Diligence', 'value': tempData[0].diligenceCompliantCount },
              { 'name': 'Prohibited', 'value': tempData[0].prohibitedCompliantCount },
              { 'name': 'Commodities', 'value': tempData[0].basicCount },
              { 'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].allowedCompliantCount - tempData[0].preferredCompliantCount - tempData[0].diligenceCompliantCount - tempData[0].prohibitedCompliantCount - tempData[0].basicCount },
            ]
          };
          }
          else {
            dt = {
              'name': standard['name'],
              'complianceName': standard['displayName'],
              'chartType': "yes-no-basic",
              'totalValue': tempData[0].totalCount,
              'complianceValue': this.organizationCompliance,
              // 'chartSize': [700, 320],
              'chartData': [
                { 'name': 'Allowed', 'value': tempData[0].allowedCompliantCount },
                { 'name': 'Preferred', 'value': tempData[0].preferredCompliantCount },
                { 'name': 'Req. Diligence', 'value': tempData[0].diligenceCompliantCount },
                { 'name': 'Prohibited', 'value': tempData[0].prohibitedCompliantCount },
                { 'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].allowedCompliantCount - tempData[0].preferredCompliantCount - tempData[0].diligenceCompliantCount - tempData[0].prohibitedCompliantCount },
              ]
            };
          }

          // this.distinctFormulaCount = tempData[0].totalCount;
          this.chartData.push(dt);
          this.chartData = [].concat(this.chartData)
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.ShowErrorMessage(error.message);
        })
    }
    else if (standard['name'] === "inditexAboveThreeYears" || standard['name'] === "inditexBelowThreeYears"
      || standard['name'] === "theListAboveThreeYears" || standard['name'] === "theListBelowThreeYears") {
      let measures = [];
      if (standard['name'] === "inditexAboveThreeYears" || standard['name'] === "inditexBelowThreeYears") {
        measures = [
          "Formulas.formula_distinct_count",
          "Formulas." + standard['name'] + "_A_cnt",
          "Formulas." + standard['name'] + "_B_cnt",
          "Formulas." + standard['name'] + "_C_cnt",
          "Formulas." + standard['name'] + "_level3_cnt",
          "Formulas.bulk_commodities_cnt"
        ]
        query = {
          "measures": measures,
          "timeDimensions": [
            {
              "dimension": "Formulas.scan_date"
            }
          ],
          "filters": this.isSingleInventory == undefined ? invent_report_filters : invent_filters
        }
        this.cubejsService.getCubeJSData(query, this.isSingleInventory == undefined ? supplier : factoryID, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
          .then(data => {
            let tempData = data['loadResponse']['results'][0]['data'];
            tempData = this.changeKey("Formulas." + standard['name'] + "_A_cnt", "levelACount", tempData);
            tempData = this.changeKey("Formulas." + standard['name'] + "_B_cnt", "levelBCount", tempData);
            tempData = this.changeKey("Formulas." + standard['name'] + "_C_cnt", "levelCCount", tempData);
            tempData = this.changeKey("Formulas." + standard['name'] + "_level3_cnt", "level3Count", tempData);
            tempData = this.changeKey("Formulas.formula_distinct_count", "totalCount", tempData);
            tempData = this.changeKey("Formulas.bulk_commodities_cnt", "basicCount", tempData);
            let dt = {};
            if (this.commoditiesControl.value) {
            dt = {
              'name': standard['name'],
              'complianceName': standard['displayName'],
              'chartType': "yes-no-basic",
              'totalValue': tempData[0].totalCount,
              'complianceValue': this.organizationCompliance,
              // 'chartSize': [1000, 320],
              'chartData': [
                { 'name': 'A', 'value': tempData[0].levelACount },
                { 'name': 'B', 'value': tempData[0].levelBCount },
                { 'name': 'C', 'value': tempData[0].levelCCount },
                { 'name': 'Level 3', 'value': tempData[0].level3Count },
                { 'name': 'Commodities', 'value': tempData[0].basicCount },
                {
                  'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].levelACount
                    - tempData[0].levelBCount - tempData[0].levelCCount
                    - tempData[0].level3Count - tempData[0].basicCount
                }
              ]
            };
            }
            else {
              dt = {
                'name': standard['name'],
                'complianceName': standard['displayName'],
                'chartType': "yes-no-basic",
                'totalValue': tempData[0].totalCount,
                'complianceValue': this.organizationCompliance,
                // 'chartSize': [1000, 320],
                'chartData': [
                  { 'name': 'A', 'value': tempData[0].levelACount },
                  { 'name': 'B', 'value': tempData[0].levelBCount },
                  { 'name': 'C', 'value': tempData[0].levelCCount },
                  { 'name': 'Level 3', 'value': tempData[0].level3Count },
                  {
                    'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].levelACount
                      - tempData[0].levelBCount - tempData[0].levelCCount
                      - tempData[0].level3Count - tempData[0].basicCount
                  }
                ]
              };
            }

            this.chartData.push(dt);
            this.chartData = [].concat(this.chartData)
            this.isLoading = false;
          })
          .catch(error => {
            this.isLoading = false;
            this.ShowErrorMessage(error.message);
          })
      }
      if (standard['name'] === "theListAboveThreeYears" || standard['name'] === "theListBelowThreeYears") {
        let standardNme = standard['name'] === "theListAboveThreeYears"
          ? "inditexAboveThreeYears"
          : (standard['name'] === "theListBelowThreeYears"
            ? "inditexBelowThreeYears" : "");
        measures = [
          "Formulas.formula_distinct_count",
          "Formulas." + standardNme + "_A_cnt",
          "Formulas." + standardNme + "_B_cnt",
          "Formulas." + standardNme + "_C_cnt",
          "Formulas.bulk_commodities_cnt"
        ]

        query = {
          "measures": measures,
          "timeDimensions": [
            {
              "dimension": "Formulas.scan_date"
            }
          ],
          "filters": this.isSingleInventory == undefined ? invent_report_filters : invent_filters
        }

        this.cubejsService.getCubeJSData(query, this.isSingleInventory == undefined ? supplier : factoryID, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
          .then(data => {
            let tempData = data['loadResponse']['results'][0]['data'];
            tempData = this.changeKey("Formulas." + standardNme + "_A_cnt", "levelACount", tempData);
            tempData = this.changeKey("Formulas." + standardNme + "_B_cnt", "levelBCount", tempData);
            tempData = this.changeKey("Formulas." + standardNme + "_C_cnt", "levelCCount", tempData);
            tempData = this.changeKey("Formulas.formula_distinct_count", "totalCount", tempData);
            tempData = this.changeKey("Formulas.bulk_commodities_cnt", "basicCount", tempData);
            let dt = {};
            if (this.commoditiesControl.value) {
             dt = {
              'name': standard['name'],
              'complianceName': standard['displayName'],
              'chartType': "yes-no-basic",
               'totalValue': tempData[0].totalCount,
               'complianceValue': this.organizationCompliance,
              // 'chartSize': [1000, 320],
              'chartData': [
                { 'name': 'A', 'value': tempData[0].levelACount },
                { 'name': 'B', 'value': tempData[0].levelBCount },
                { 'name': 'C', 'value': tempData[0].levelCCount },
                { 'name': 'Commodities', 'value': tempData[0].basicCount },
                {
                  'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].levelACount
                    - tempData[0].levelBCount - tempData[0].levelCCount - tempData[0].basicCount
                }
              ]
            };
            }
            else {
              dt = {
                'name': standard['name'],
                'complianceName': standard['displayName'],
                'chartType': "yes-no-basic",
                'totalValue': tempData[0].totalCount,
                'complianceValue': this.organizationCompliance,
                // 'chartSize': [1000, 320],
                'chartData': [
                  { 'name': 'A', 'value': tempData[0].levelACount },
                  { 'name': 'B', 'value': tempData[0].levelBCount },
                  { 'name': 'C', 'value': tempData[0].levelCCount },
                  {
                    'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].levelACount
                      - tempData[0].levelBCount - tempData[0].levelCCount - tempData[0].basicCount
                  }
                ]
              };

            };

            this.chartData.push(dt);
            this.chartData = [].concat(this.chartData)
            this.isLoading = false;
          })
          .catch(error => {
            this.isLoading = false;
            this.ShowErrorMessage(error.message);
          })
      }
    }
    else {
      if (standard.hasOwnProperty('id')) {//if is complianceRule
        !this.hasBulkScope ?
                query = {
                  "measures": [
                    "Formulas.formula_distinct_count",
                    "Formulas.brand_compliance_count_without_basics",
                    "Formulas.bulk_commodities_cnt"
                  ],
                  "timeDimensions": [
                    {
                      "dimension": "Formulas.scan_date"
                    }
                  ],
                  "filters": this.isSingleInventory == undefined ? invent_report_filters : invent_filters

                }
                : query = {
                  "measures": [
                    "Formulas.formula_distinct_count",
                    "Formulas.brand_compliance_count"
                  ],
                  "timeDimensions": [
                    {
                      "dimension": "Formulas.scan_date"
                    }
                  ],
                  "filters": this.isSingleInventory == undefined ? invent_report_filters : invent_filters

                };
                //this.distinctFormulaCount contains the total count of formulas
                //this is done because the above query get only the compliant formulas based on the complianceRules
                //the filters are query-based, not measure-based
        this.cubejsService.getCubeJSData(query, this.isSingleInventory == undefined ? supplier : factoryID, standard['id'], this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
                  .then(data => {
                    let tempData = data['loadResponse']['results'][0]['data'];
                    tempData = !this.hasBulkScope ? this.changeKey("Formulas.brand_compliance_count_without_basics", "complianceCount", tempData) : this.changeKey("Formulas.brand_compliance_count", "complianceCount", tempData);
                    tempData = this.changeKey("Formulas.formula_distinct_count", "totalCount", tempData);
                    if (!this.hasBulkScope) { tempData = this.changeKey("Formulas.bulk_commodities_cnt", "basicCount", tempData);}
                    let dt = {};
                    if (this.commoditiesControl.value && !this.hasBulkScope){
                      dt = {
                        'overallFilters': query['filters'],
                        'name': standard['name'],
                        'isBrand': true,
                        'complianceName': standard['displayName'],
                        'id': standard['id'],
                        'chartType': "yes-no",
                        'totalValue': tempData[0].totalCount,
                        'complianceValue': this.organizationCompliance,
                        'scope': this.connectedBrandsScopeCriteria.find(res => res.id == standard['id']).scopeValue,
                        // 'chartSize': [500, 320],
                        'chartData': [
                          { 'name': 'Verified', 'value': tempData[0].complianceCount },
                          { 'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].complianceCount - tempData[0].basicCount },
                          { 'name': 'Commodities', 'value': tempData[0].basicCount }
                        ]
                      };
                    }
                    else{
                      dt = {
                        'overallFilters': query['filters'],
                        'name': standard['name'],
                        'isBrand': true,
                        'complianceName': standard['displayName'],
                        'id': standard['id'],
                        'chartType': "yes-no",
                        'totalValue': tempData[0].totalCount,
                        'complianceValue': this.organizationCompliance,
                        'hasBulkScope': this.hasBulkScope,
                        'scope': this.connectedBrandsScopeCriteria.find(res => res.id == standard['id']).scopeValue,
                        // 'chartSize': [500, 320],
                        'chartData': [
                          { 'name': 'Verified', 'value': tempData[0].complianceCount },
                          { 'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].complianceCount }
                          
                        ]
                      };
                    }
                    
                    this.chartData.push(dt);
                    this.chartData = [].concat(this.chartData)
                    this.isLoading = false;
                  })
                  .catch(error => {
                    this.isLoading = false;
                    this.ShowErrorMessage(error.message);
                  })
              
            
      }
      else {
        let standardName = standard['name'].replaceAll(/\s/g, '').replace(/[A-Z]/g, (letter, index) => { return index == 0 ? letter.toLowerCase() : '_' + letter.toLowerCase() });
        let complianceColumnName = "Formulas." + standardName + "_total_compliant_cnt";
        query = {
          "measures": [
            "Formulas.formula_distinct_count",
            complianceColumnName,
            "Formulas.bulk_commodities_cnt"
          ],
          "timeDimensions": [
            {
              "dimension": "Formulas.scan_date"
            }
          ],
          "filters": this.isSingleInventory == undefined ? invent_report_filters : invent_filters

        }

        this.cubejsService.getCubeJSData(query, this.isSingleInventory == undefined ? supplier : factoryID, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
          .then(data => {
            let tempData = data['loadResponse']['results'][0]['data'];
            tempData = this.changeKey(complianceColumnName, "complianceCount", tempData);
            tempData = this.changeKey("Formulas.bulk_commodities_cnt", "basicCount", tempData);
            tempData = this.changeKey("Formulas.formula_distinct_count", "totalCount", tempData);
            let dt = {};
            if (this.commoditiesControl.value) {
            dt = {
              'name': standard['name'],
              'complianceName': standard['displayName'],
              'chartType': "yes-no-basic",
              'totalValue': tempData[0].totalCount,
              'complianceValue': this.organizationCompliance,
              // 'chartSize': [500, 320],
              'chartData': [
                { 'name': 'Verified', 'value': tempData[0].complianceCount },
                { 'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].complianceCount - tempData[0].basicCount },
                { 'name': 'Commodities', 'value': tempData[0].basicCount }
              ]
            };
            }
            else {
              dt = {
                'name': standard['name'],
                'complianceName': standard['displayName'],
                'chartType': "yes-no-basic",
                'totalValue': tempData[0].totalCount,
                'complianceValue': this.organizationCompliance,
                // 'chartSize': [500, 320],
                'chartData': [
                  { 'name': 'Verified', 'value': tempData[0].complianceCount },
                  { 'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].complianceCount - tempData[0].basicCount },
                ]
              };
            }
            // this.distinctFormulaCount = tempData[0].totalCount;
            this.chartData.push(dt);
            this.chartData = [].concat(this.chartData)
            this.isLoading = false;
          })
          .catch(error => {
            this.isLoading = false;
            this.ShowErrorMessage(error.message);
          })
      }
    }

  }
  fillChartWithData(standard) {
    if (standard['name'] == "Inditex") {
      standard = {
        "name": "inditexAboveThreeYears",
        "displayName": "Inditex >3"
      };
      this.getFormulasFirst(standard);
      standard = {
        "name": "inditexBelowThreeYears",
        "displayName": "Inditex <3"
      };
      this.getFormulasFirst(standard);

    }
    else if (standard['name'] == "theList") {
      standard = {
        "name": "theListAboveThreeYears",
        "displayName": "The List >3"
      };
      this.getFormulasFirst(standard);
      standard = {
        "name": "theListBelowThreeYears",
        "displayName": "The List <3"
      };
      this.getFormulasFirst(standard);

    }
    else {
      this.getFormulasFirst(standard);
    }
  }
  changeEndDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value !== null) {
      this.chartData = [];
      this.selectedStandards.value.forEach(standard => {
        this.fillChartWithData(standard);
      });
    }
  }

  showStandardChart(event) {
    this.mySelect.close();
    let standard = event.source.value;
    if (event.source._selected) {
      this.fillChartWithData(standard);
    }
    else {
      if (standard['name'] === "zdhcGateway") {
        this.iszdhcGateway = false;
      }
      this.chartData = this.chartData.filter(function (obj) {
        if (standard.name == "Inditex") {
          return (obj['complianceName'] !== "Inditex >3" && obj['complianceName'] !== "Inditex <3");
        }
        else if (standard.name == "theList") {
          return (obj['complianceName'] !== "The List >3" && obj['complianceName'] !== "The List <3");
        }
        else {
          return obj['complianceName'] !== standard.displayName;
        }
      });
    }
  }

  onChangeCommodities(event) {
    this.chartData = [];
    this.selectedStandards.value.forEach(standard => {
      this.fillChartWithData(standard);
    });
  }
  onChangeScope(event) {
    this.chartData = [];
    this.selectedStandards.value.forEach(standard => {
      this.fillChartWithData(standard);
    });
  }

  onSearchSupplierSelectCharts() {

    this.selectedSupplier = '';
    let sp = this.suggestedList.find(supplier => this.selectedSupplierName === supplier.name);
    this.selectedSupplier = sp.id;
    this.selectedCountry = sp.countryName
    this.rememberSelectedSupplier();
    this.rememberSelectedCountry();
    this.chartData = [];
    this.selectedStandards.value.forEach(standard => {
      this.fillChartWithData(standard);
    });
  }

  searchThis(searchText) {
    if (searchText.length > 0) {
      this.selectedSupplierName = searchText
      this.suggestedList = this.globalsService.brands.filter(res => {
        if (res.sharedWith == true && res.organizationType == 2 && res.relation === "Connected" && res.id != this.globalsService.profile['id']) {
          return res.name.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase())
            || res.identification.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase())
            || res.countryName.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase())
            || res.Higg.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase())
            || res.Oar.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase())
            || res.activity.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase())
            || res.FFC.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase());
        }
      });
    } else {
      this.getMySupplierPartners();
    }
  }
  private getBrandsWithScopeAndCompliance() {
    this.http.get<any>(this.globalsService.baseHref + '/complianceCriteria/getBrandsWithScopeAndCompliance').subscribe(
      (response) => {
        this.connectedBrandsScopeCriteria = response['brands'];
        this.defaultZDHCMemberScopeValue = response['defaultZDHCMemberScopeValue'];
        this.defaultZDHCComplianceValue = response['defaultZDHCComplianceValue'];
        this.defaultNonZDHCMemberScopeValue = response['defaultNonZDHCMemberScopeValue'];
        this.defaultNonZDHCComplianceValue = response['defaultNonZDHCComplianceValue'];
      },
      (err) => {
        alert("Error: Could not fetch list of scopeValue");
      }
    );
  }
}

